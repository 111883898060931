import { mapGetters } from "vuex";
import { URL } from "@/api/report.js";
import InputMoney from "@/components/inputMoney.vue";

export default {
  components: {
    InputMoney,
  },
  data() {
    return {
      report_period: null,
      typeDownload: null,
      arrayYear: [],
      activeTabs: "output",
      companyId: null,
      report_year: null,
      rangePeriod: 4,
      indexRole: null,
      showModalTaxRefund: false,
      moneyTaxRefund: 0,
      dialogWidth: "30%",
      is_updating_report: false,
      idInterval: null,
      loadingButton: false,
    };
  },
  methods: {
    async getDefaulPeriod(from_interval=false) {
      this.loadingButton = true
      const params = {
        company_id: this.getSelectedCompany.id,
      };

      const result = await this.$request({
        url: URL.GET_DEFAUL_PERIOD,
        params,
      });
      const res = result.data;
      if (res.code == 200) {
        if (!from_interval) {
          this.report_period = res.data.default_period;
          this.report_year = res.data.default_year;
          this.$store.dispatch("setReportPeriod", res.data.default_period);
          this.$store.dispatch("setreportYear", res.data.default_year);
        }

        this.loadingButton = false
        this.is_updating_report = res.data.is_updating_report;
        clearInterval(this.idInterval);
        if (this.is_updating_report) {
          this.idInterval = setInterval(() => {
            this.getDefaulPeriod(true);
          }, 5000);
        }
      }
    },

    async getDetailReport() {
      const params = {};
      params.company_id = this.getSelectedCompany.id;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;
      params.report_period = this.getReportPeriod;

      if (this.getSelectedCompany.id) {
        const result = await this.$request({
          url: URL.REPORT,
          params,
        });
        const res = result.data;

        if (res.code == 200) {
          this.$store.dispatch(
            "setTaxRefundProposal",
            res.data.tax_refund_proposal
          );
          this.$store.dispatch("setSubmitReport", res.data.submit_report);
        }
      }
    },

    changeTabs() {
      if (this.is_updating_report) {
        this.getDefaulPeriod(true);
      }
      this.$router.push(this.activeTabs);
    },

    taxRefundRequest() {
      this.showModalTaxRefund = true;
    },

    async confirmTaxRefund() {
      const data = {
        period_number: this.getReportPeriod,
        year: this.getReportYear,
        company_id: this.getSelectedCompany.id,
        value: this.moneyTaxRefund,
      };
      const result = await this.$request({
        url: URL.ITAX_REFUND,
        method: "POST",
        data,
      });
      const res = result.data;
      if (res.code == 200) {
        this.showModalTaxRefund = false;
        this.$swal({
          text: res.messages,
          icon: "success",
          timer: "3000",
        });
        this.$store.dispatch("setValueRefundProposal", this.moneyTaxRefund);
      }
    },

    async submitReport(status) {
      this.$swal({
        title: status == "reported" ? "Hủy nộp báo cáo" : "Nộp báo cáo?",
        showCancelButton: true,
        confirmButtonText: "Xác nhận",
        cancelButtonText: "Hủy",
        confirmButtonColor: "#3b82f6",
        cancelButtonColor: "#adadad",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            period_number: this.getReportPeriod,
            year: this.getReportYear,
            company_id: this.getSelectedCompany.id,
          };
          let urlApi = null;
          if (status == "reported") urlApi = URL.ITAX_CANCEL;
          else urlApi = URL.ITAX_SUBMIT;
          const result = await this.$request({
            url: urlApi,
            method: "POST",
            data,
          });
          const res = result.data;

          if (res.code == 200) {
            this.$swal({
              text: res.messages,
              icon: "success",
              timer: "3000",
            });
            this.getDetailReport();
          } else {
            this.$swal({
              text: res.errors[0].year,
              icon: "error",
              timer: "3000",
              width: "450",
              padding: "10px",
            });
          }
        }
      });
    },

    resetFormItaxRefund() {
      this.moneyTaxRefund = 0;
    },

    setDialogWidth() {
      let windowSize = document.body.clientWidth;
      if (windowSize < 767) {
        this.dialogWidth = "90%";
      } else {
        this.dialogWidth = "30%";
      }
    },

    async downloadFile(type) {
      this.loadingButton = true;
      const params = {};
      params.company_id = this.getSelectedCompany.id;
      params.report_type = this.routeName;
      params.report_year = this.getReportYear;
      params.report_period = this.getReportPeriod;
      params.download = type;

      const result = await this.$request({
        responseType: "blob",
        url: URL.REPORT,
        params,
      });

      try {
        const message = JSON.parse(await result.data.text())
        if (message['messages'] == 'processing') {
          this.$swal({
            text: "Hệ thống đang xử lý file yêu cầu, vui lòng đợi trong ít phút...",
            icon: "success",
            timer: "3000",
            width: "450",
            padding: "10px",
          }).then(() => {
              this.$store.dispatch("setIsDownloadProcessing", true);
          });
        } else if (message['messages'] == 'large_file') {
          this.$swal({
            text: "Hệ thống chỉ hỗ trợ tệp PDF cho các báo cáo < 1500 bản ghi. Vui lòng sử dụng tệp XLSX",
            icon: "warning",
            timer: "3000",
            width: "450",
            padding: "10px",
          }).then(() => {
              this.$store.dispatch("setIsLargePDF", true);
          });
        }
        this.loadingButton = false;
        return;
      } catch {;}

      this.downloadfile(result.data, type);
      this.loadingButton = false;
    },

    downloadfile(blob, fileType) {
      var a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      let url = window.URL.createObjectURL(blob);
      a.href = url;
      const tab_name_vietnamese_obj = {
        output: "bang_ke_ban_ra",
        input: "bang_ke_mua_vao",
        tax_return: "to_khai_thue_gtgt",
        summary: "bang_ke_tong_hop",
        report_131: "tong_hop_131",
        report_331: "tong_hop_331",
      };

      if (fileType == "excel") {
        fileType = "xlsx";
      }

      // Build file name
      let fileName = `${this.getSelectedCompany.mst}_${tab_name_vietnamese_obj[this.routeName]}`
      if (['output', 'input', 'tax_return'].indexOf(this.routeName) != -1) {
        fileName += `_${this.getSelectedCompany.kieuKeKhai}_${this.report_period}`;
      }
      fileName += `_${this.report_year}.${fileType}`;

      a.download = fileName;
      a.click();
      window.URL.revokeObjectURL(url);
    },

    getArrYear() {
      this.arrayYear = [];
      const d = new Date();
      let ds = d.getFullYear();
      for (let index = 0; index < 5; index++) {
        if (ds - index >= this.getSelectedCompany.start_year) {
          this.arrayYear.push(ds - index);
        }
      }

      if (!this.getReportPeriod) {
        this.report_period = Math.ceil(d.getMonth() / 3);
      } else {
        this.report_period = this.getReportPeriod;
      }
      if (!this.getReportYear) {
        this.report_year = ds;
      } else {
        this.report_year = this.getReportYear;
      }
    },

    changeDeclaration() {
      this.$store.dispatch("setReportPeriod", this.report_period);
      this.$store.dispatch("setIsManual", true);
    },

    changeYear() {
      this.$store.dispatch("setreportYear", this.report_year);
      this.$store.dispatch("setIsManual", true);
    },

    backToTop() {
      if (this.$refs.scrollbar) {
        this.$refs.scrollbar.setScrollTop(0);
      }
    },
  },

  created() {
    if (this.getSelectedCompany.kieuKeKhai == "T") {
      this.rangePeriod = 12;
    }

    this.getArrYear();
    this.activeTabs = this.routeName;
    let value = this.$store.state;
    this.$store.state.setRoleUser.role;
    this.indexRole = value.setRoleUser.indexRole;

    if (!this.getIsManual) {
      this.getDefaulPeriod();
    }

    this.setDialogWidth();
  },

  mounted() {
    window.onresize = () => {
      return (() => {
        this.setDialogWidth();
      })();
    };
  },

  beforeUpdate() {
    this.moneyTaxRefund = this.getValueRefundProposal;
  },

  computed: {
    ...mapGetters([
      "getSelectedCompany",

      "getIsReportLoading",
      "getReportPeriod",
      "getReportYear",
      "getSubmitReport",
      "getTaxRefundProposal",
      "getStartYear",
      "getValueRefundProposal",
      "getIsManual",
      "getUserRole",

      "getIsDownloadable",
      "getIsDownloadProcessing",
      "getIsLargePDF",
    ]),
    routeName() {
      return this.$route.name;
    },
  },

  watch: {
    routeName: function (newVal) {
      if (newVal == "output") {
        this.activeTabs = "output";
      }
      this.backToTop();
    },

    report_period() {
      this.backToTop();
    },

    report_year() {
      this.backToTop();
    },

    getSelectedCompany(company) {
      if (company.id == -1) return;
      this.getArrYear();
      this.backToTop();
      if (!this.getIsManual) {
        this.getDefaulPeriod();
      }

      if (company.kieuKeKhai == "T") {
        this.rangePeriod = 12;
      } else {
        this.rangePeriod = 4;
      }
    },
  },
  beforeRouteLeave() {
    clearInterval(this.idInterval);
  },
};
